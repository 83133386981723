/* muli-200normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Muli Extra Light '),
    local('Muli-Extra Light'),
    url('./files/muli-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/muli-latin-200.woff') format('woff'); /* Modern Browsers */
}

/* muli-200italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Muli Extra Light italic'),
    local('Muli-Extra Lightitalic'),
    url('./files/muli-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/muli-latin-200italic.woff') format('woff'); /* Modern Browsers */
}

/* muli-300normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Muli Light '),
    local('Muli-Light'),
    url('./files/muli-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/muli-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* muli-300italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Muli Light italic'),
    local('Muli-Lightitalic'),
    url('./files/muli-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/muli-latin-300italic.woff') format('woff'); /* Modern Browsers */
}

/* muli-400normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Muli Regular '),
    local('Muli-Regular'),
    url('./files/muli-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/muli-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* muli-400italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Muli Regular italic'),
    local('Muli-Regularitalic'),
    url('./files/muli-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/muli-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* muli-600normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Muli SemiBold '),
    local('Muli-SemiBold'),
    url('./files/muli-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/muli-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* muli-600italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Muli SemiBold italic'),
    local('Muli-SemiBolditalic'),
    url('./files/muli-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/muli-latin-600italic.woff') format('woff'); /* Modern Browsers */
}

/* muli-700normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Muli Bold '),
    local('Muli-Bold'),
    url('./files/muli-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/muli-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* muli-700italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Muli Bold italic'),
    local('Muli-Bolditalic'),
    url('./files/muli-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/muli-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

/* muli-800normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Muli ExtraBold '),
    local('Muli-ExtraBold'),
    url('./files/muli-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/muli-latin-800.woff') format('woff'); /* Modern Browsers */
}

/* muli-800italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Muli ExtraBold italic'),
    local('Muli-ExtraBolditalic'),
    url('./files/muli-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/muli-latin-800italic.woff') format('woff'); /* Modern Browsers */
}

/* muli-900normal - latin */
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Muli Black '),
    local('Muli-Black'),
    url('./files/muli-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/muli-latin-900.woff') format('woff'); /* Modern Browsers */
}

/* muli-900italic - latin */
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Muli Black italic'),
    local('Muli-Blackitalic'),
    url('./files/muli-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/muli-latin-900italic.woff') format('woff'); /* Modern Browsers */
}

